<template>
    <div class="content-wrapper">
        <PageHeader :screenName="form.tipo + ' ' + form.codigo" :linkItems="linkItems"/>

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2">
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section class="form" v-if="!loadingData">
                <b-form>
                    <b-row>
                        <b-col lg="6" md="12" sm="12">
                            <b-form-group :label="'Número '+form.tipo "
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.codigo }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12" sm="12">
                            <b-form-group :label="form.tipo + ' registrado por'"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.usuario }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group :label="'Data de ' + form.tipo"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ formatDate(form.criacao, 'DD/MM/YYYY') }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Lote"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.lote }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Fabricante"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.fabricante.descricao }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Vacina"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.vacina.nome_tecnico }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Validade"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ formatDate(form.validade, 'DD/MM/YYYY') }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group :label="'Motivo de '+ form.tipo"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.motivo }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Observações"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.observacoes ? form.observacoes : '-' }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                    </b-row>
                </b-form>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea, BLink } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'

export default {
    title: 'Detalhes ajuste',
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, PageHeader, VuePerfectScrollbar, ErrorModal, BLink
    },

    data() {
        return{
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Estoque',
                    routeName: 'estoque-list'
                },
                {
                    name: 'Ajuste de Estoque',
                    routeName: 'ajuste-estoque-list'
                },
                {
                    name: 'Carregando dados...',
                    active: true
                }
            ],
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            form: {
                codigo: '',
                usuario: '',
                criacao: '',
                lote: '',
                fabricante: '',
                vacina: '',
                validade: '',
                quantidade: '',
                motivo: '',
                observacoes: '',
                tipo: '',
            },
            loadingData: true,
        }
    },
    mounted() {
    if(typeof this.$route.params.idMovimentacao == "undefined") {
        this.openModalError();
        return;
    }
    this.$http.get(this.$api.movimentacaoParameter(this.$route.params.idMovimentacao)).then(({ data }) => {
        this.preenchimentoFormulario(data);
    })
    },
    methods: {
        preenchimentoFormulario(dados){
            this.form.codigo = '#'+ dados.codigo;
            this.form.usuario = dados.criador;
            this.form.criacao = dados.cadastro;
            this.form.lote = dados.itens[0].lote_unidade_origem.lote.numero_lote;
            this.form.fabricante = dados.itens[0].lote_unidade_origem.lote.fabricante;
            this.form.vacina = dados.itens[0].lote_unidade_origem.lote.vacina;
            this.form.validade = dados.itens[0].lote_unidade_origem.lote.validade;
            this.form.quantidade = dados.itens[0].quantidade;
            this.form.motivo = dados.motivo;
            this.form.observacoes = dados.observacao;
            this.form.tipo = dados.tipo_movimentacao == 'Adicao' ? 'Adição' : 'Descarte';
            this.linkItems[2].name =  this.form.tipo + ' ' + this.form.codigo;
            this.loadingData = false;
        },
        redirecionarLista() {
            this.$router.push({ name: 'ajuste-estoque-list' });
        },

        openModalError() {
            this.modalError.showModal = true;
        },
        formatDate(value, format){
           return moment(String(value)).format(format);
        }
    }
}
</script>

<style scoped>
.perfis{
    background-color: #2772C0;
    color: white;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}

.altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_textarea_scrollbar{
    max-height:113px;
    word-break: break-all;
    padding-right: 15px;
}
</style>